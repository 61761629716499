import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from '../images/montering/montering_main.jpg';
import img_1 from '../images/montering/montering_2.jpg';
import img_2 from '../images/montering/montering_3.jpg';
import img_3 from '../images/montering/montering_4.jpg';
import img_4 from '../images/montering/montering_5.jpg';
import favicon from '../images/favicon.ico';

function Montering() {
    return (
        <Layout>
            <Helmet>
                <title>Smide montering - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Behöver du hjälp med rivning? Vi erbjuder även montering och installation efter önskemål och kundbehov" />
                <meta name="keywords" content="Montering, rivning, installation, yrkesperson" />
                <meta property="og:title" content="Montering - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/montering" />
                <link rel="cannonical" href="https://danasplat.se/montering" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-montering bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Montering &amp; Rivning</h1>
                    </div>
                </div>
                <Breadcrumb page="Montering &amp; Rivning"/>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Montering och rivning</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Behöver du hjälp med rivning? I så fall har du hittat helt rätt eftersom vi vet precis vad som krävs för att kunna förse dig med bästa möjliga hjälp. Med vår hjälp kommer du att få en rivning där allt kommer att gå till så som det ska.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi erbjuder även montering och installation efter önskemål och kundbehov. Vi har kvalificerade personal som ser till att arbetet görs korrekt och säkert. Montering och installation är okomplicerad procedur när den utförs av en kvalificerad yrkesperson.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Välkommen att kontakta oss!</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="smide montering och installation" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-32">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="smide rivning " className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="smide installation " className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="Monteringsanvisning smidesstaket " className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                                <img alt="smide och montering" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_4} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Montering;